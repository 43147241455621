<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title m-0 p-0">{{ propGideUser.user_name }}'s {{cvCardTitle}}
            </h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="yjsDocsAdd">{{cvbtnAddNew}}</b-button>
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <!-- Frontend Search -->
              <!-- <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>Frontend Search -->

              <!-- Backend Search -->
              <b-col class="col-12 col-sm-8 col-md-5 col-lg-4 mb-2">
                <b-form-input class="form-control"
                  @input="searchdocsWithParams"
                  v-model="search_param"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col><!-- Backend Search -->
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="docsObjList && docsObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="docsObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage"
                  >
                  <template v-slot:cell(yjsdocs_name)="data">
                    {{ data.item.yjsdocs_name}}<br>
                    <b-button class="actionIcon mr-1" @click="yjsDocsEdit('MODAL', data.item)" v-if="!data.item.editable"><i class="ri-ball-pen-fill m-0 font-size-20"></i></b-button>
                    <b-button class="actionIcon ml-2" @click="showdocDeleteDialog(data.item)" size="sm"><i class="ri-delete-bin-line m-0 font-size-20"></i></b-button>
                    <b-button class="actionIcon ml-2" title="Edit the document" @click="DocumentEdit(data.item)" size="sm"><i class="fa-solid fa-users-line m-0 font-size-20"></i></b-button>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModeldocAdd"
      scrollable
      :title="cvAddModalHeader"
      hide-footer
    >
      <YjsDocsAdd  :propYjsDocObj="propsyjsDocAddObj" :propGideUser="propGideUser" :propOpenedInModal="true" @emitClosedocAddModal="closedocAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="yjsDocsAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closedocAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showModeldocEdit"
      scrollable
      :title="cvEditModalHeader"
      hide-footer
    >
      <YjsDocsEdit :propOpenedInModal="true" :propYjsDocObj="docsEditObj" @emitClosedocEditModal="closedocEditModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="yjsDocsEdit('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closedocEditModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showModeldocDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
      <p>{{cvDelMessage}}</p>
      <template #modal-footer="">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="showModeldocDelete = false">
          {{cvbtnModalCancel}}
        </b-button>
        <b-button size="sm" variant="primary" @click="yjsDocsDelete()">
          {{cvbtnModalDelOk}}
        </b-button>
      </template>
    </b-modal>

    <b-modal
      v-model="showEditDocumentModal"
      scrollable
      :title="cvEditDocumentModalHeader"
      size="xxl"
      hide-footer
    >
      <SpcmEssayEditor :propYJSDocObj="documentEditObj"/>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { YjsDocs } from "../../../FackApi/api/YjsDocs.js"
import YjsDocsAdd from "./YjsDocsAdd.vue"
import YjsDocsEdit from "./YjsDocsEdit.vue"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"
import SpcmEssayEditor from "../SPCM/SpcmEssayEditor.vue"

export default {
  name: "DocsList",
  components: {
    YjsDocsAdd,
    YjsDocsEdit,
    SpcmEssayEditor
  },
  props: {
    propGideUser: {
      type: Object
    },
    propYjsDocObj: {
      type: Object
    }
  },
  data () {
    return {
      cvCardTitle: "Document List",
      cvbtnAddNew: "Add",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Documment",
      cvEditDocumentModalHeader: "Edit Document",
      cvAddModalHeader: "Add Document",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "doc",
      showModeldocAdd: false,
      showModeldocEdit: false,
      showModeldocDelete: false,
      showEditDocumentModal: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Document name", key: "yjsdocs_name", class: "text-left align-text-top", sortable: true },
        { label: "Document Type", key: "yjsdocs_code", class: "text-left align-text-top", sortable: true }
      ],
      docsObjList: [],
      docsEditObj: null,
      documentEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      delObj: null,
      whereFilter: {},
      search_param: "",
      currentBatchNo: 1,
      dataPerPage: 100,
      showLoadMoreBtn: true,
      gidUserId: null,
      propsyjsDocAddObj: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async beforeMount () {
    // Auth Access for Current User Role
    if (this.userData.user_role == "USERROLE11114") {
      // We will display only the sessions of the user, It is necessary when the user has multiple sessions and he/she wants to view the session
      // DONT CARE CASE
    }
    else if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }

    if (this.userData.user_role == "USERROLE11114") {
      this.columns = [
        { label: "# ", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "Document name", key: "yjsdocs_name", class: "text-left align-text-top", sortable: true },
        { label: "Document Type", key: "yjsdocs_code", class: "text-left align-text-top", sortable: true }
      ]
    }
  },
  async mounted () {
    socialvue.index()
    this.gidUserId = this.propGideUser && this.propGideUser.user_id ? this.propGideUser.user_id : this.userData.user_id
    this.yjsDocsList()
  },
  methods: {
    /**
     * searchdocsWithParams
     */
    async searchdocsWithParams () {
      if (this.whereFilter.search_param.length == 0) {
        this.showLoadMoreBtn = true
      }
      this.currentBatchNo = 1
      this.yjsDocsList()
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.yjsDocsList(true)
    },
    /**
     * yjsDocsList
     */
    async yjsDocsList (loadViaLoadMore = false) {
      try {
        // setting data for pagination
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.search_param = this.search_param
        this.whereFilter.gide_user_id = this.gidUserId

        let docsListResp = await YjsDocs.yjsDocsList(this, this.whereFilter)
        if (docsListResp.resp_status) {
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.docsObjList = [ ...docsListResp.resp_data.data ]
          }
          else {
            this.docsObjList = [ ...this.docsObjList, ...docsListResp.resp_data.data ]
          }
          this.showLoadMoreBtn = true

          // Adding the serial numbers:
          this.docsObjList.forEach((s, index) => { s.id = index + 1 })
          this.totalRows = this.docsObjList.length
        }
        else {
          this.showLoadMoreBtn = false
          this.toastMsg = docsListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at yjsDocsList() and Exception:", err)
      }
    },
    /**
     * yjsDocsAdd
     */
    yjsDocsAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/docs_add")
        }
        else {
          this.propsyjsDocAddObj = {
            module_name: this.propYjsDocObj.module_name,
            module_object_id: this.propYjsDocObj.spcm_id
          }
          this.showModeldocAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at yjsDocsAdd() and Exception:", err.message)
      }
    },
    /**
     * yjsDocsEdit
     */
    yjsDocsEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/docs_edit/" + this.docsEditObj.yjsdocs_id)
        }
        else {
          this.docsEditObj = item
          this.showModeldocEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at yjsDocsEdit() and Exception:", err.message)
      }
    },
    /**
     * Document Edit
     */
    DocumentEdit (item) {
      try {
        this.documentEditObj = item
        this.showEditDocumentModal = true
      }
      catch (err) {
        console.error("Exception occurred at DocumentEdit() and Exception:", err.message)
      }
    },
    /**
     * showdocDeleteDialog
     */
    showdocDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModeldocDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showdocDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * yjsDocsDelete
     */
    async yjsDocsDelete () {
      try {
        let docsDelResp = await YjsDocs.yjsDocsDelete(this, this.delObj.yjsdocs_id)
        await ApiResponse.responseMessageDisplay(this, docsDelResp)

        if (docsDelResp && !docsDelResp) {
          this.showModeldocDelete = false
          return false
        }

        let index = this.docsObjList.indexOf(this.delObj)
        this.docsObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModeldocDelete = false
      }
      catch (err) {
        console.error("Exception occurred at yjsDocsDelete() and Exception:", err.message)
      }
    },
    /**
     * closedocAddModal
     */
    closedocAddModal (docsAddData) {
      try {
        if (docsAddData) {
          if (this.docsObjList && this.docsObjList.length >= 1) {
            let docsObjLength = this.docsObjList.length
            let lastId = this.docsObjList[docsObjLength - 1]["id"]
            docsAddData.id = lastId + 1
          }
          else {
            this.docsObjList = []
            docsAddData.id = 11111
          }

          docsAddData.created_on = moment()
          this.docsObjList.unshift(docsAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModeldocAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closedocsAddModal() and Exception:", err.message)
      }
    },
    /**
     * closedocEditModal
     */
    closedocEditModal () {
      try {
        this.showModeldocEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closedocEditModal() and Exception:", err.message)
      }
    },
    /**
     * openCollaborativeEditor
     */
    openCollaborativeEditor (yjsdocs) {
      window.open(`/spcm_essay_editor/${yjsdocs.yjsdocs_id}`)
    }
  }
}
</script>
