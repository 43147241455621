<template lang="">
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card class="p-3">
          <b-row>
            <h5 class="m-bold pl-2 w-100 pr-2 mb-3"> Profile Building Reco
              <i class="pt-5px fa-solid fa-heart-circle-plus pull-right primary-color pointer" aria-hidden="true" title="Profile Building"  v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'" @click="openPbaSelectionModal()"></i>
            </h5>
          </b-row>

          <div class="d-flex" >
            <!--Backend Search  -->
            <input
              class="form-control w-full mb-1"
              v-model="whereFilter.search_param"
              type="search"
              @input="searchFromBackend"
              :placeholder="cvSearchText" /><!--Backend Search  -->
          </div>

          <!-- Profile Building Activity Filter -->
          <select class="form-control mb-2" v-model="whereFilter.pba_type" @change="getProileBuildingReco()" v-if="pbaFilterObjList" title="Activity type">
            <option v-for="(filterObj, index) of pbaFilterObjList" :key="(index+1)" :value="filterObj.pba_type">
              {{filterObj.pba_type}}
            </option>
          </select><!-- Profile Building Activity Filter -->

          <!-- Recomendation Master List -->
          <b-modal size="xl" :title="modalTitle" v-model="showMasterRecoList" no-close-on-backdrop no-close-on-esc @hide="closeProfBuildRecoListModal">
            <GPathrRoadmapExtraList :propSpcmObj="propSpcmObj" @emitAddProfBuildReco="emitAddProfBuildReco" />
            <template #modal-footer="">
              <b-button size="sm" class="pull-left" variant="secondary" @click="closeProfBuildRecoListModal()">
                {{modalCancelBtnText}}
              </b-button>
            </template>
          </b-modal><!-- Recomendation Master List -->

          <div v-if="profileBuildingRecoObj && Object.keys(profileBuildingRecoObj).length > 0" class="mt-4">
            <!-- NB: We are keeping the recommended profile building activities in spcm_notes table -->
            <ol style="padding-inline-start:20px">
              <li class="mb-3" v-for="(activityList, profBuilActivityType) in profileBuildingRecoObj" :key="profBuilActivityType">
                <h6>{{profBuilActivityType}}</h6>
                <ul style="list-style: disc; padding-inline-start:5px ">
                  <li v-for="(activity, index) in activityList" :key="index">
                    <span class="primary-color">
                      {{activity.title}}
                      <i class="pt-5px fa-solid fa-trash-can pull-right primary-color pointer ml-3" title="Remove recommendation" aria-hidden="true"  v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'" @click="removeProfBuildReco(activity)"></i>
                    </span><br/>
                    <span style="font-size: 0.7rem">
                      {{activity.description && activity.description != "null" ? activity.description : ""}}&nbsp;&nbsp;
                      <i class="fa fa-arrow-right primary-color pointer" title="Go to Activity Link" aria-hidden="true"  v-if="activity.url && (userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118')" @click="gotoActivityURL(activity.url)"></i>
                    </span>
                  </li>
                </ul>
              </li>
            </ol>
          </div>

          <div v-else>
            No recommendations found for profile building / extracurricular activities.&nbsp;
            <span v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">
              <span class="primary-color pointer" @click="showMasterRecoList=true">Click here</span> to recommend.</span><br/>
          </div>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { SpcmNotess } from "../../../FackApi/api/spcmNotes"
import ApiResponse from "../../../Utils/apiResponse"
import GPathrRoadmapExtraList from "../GPath/GPathrRoadmapExtraList.vue"

export default {
  name: "SpcmStudentProfileBuildingReco",
  mounted () {
    this.getProileBuildingReco()
  },
  data () {
    return {
      spcm_id: null,
      profileBuildingRecoObj: null,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Student's Profile Building Recommendations",
      cvSearchText: "Search...",
      whereFilter: {
        search_param: "",
        pba_type: ""
      },
      pbaFilterObjList: null,
      showMasterRecoList: false,
      modalTitle: "Profile Building Activities",
      modalCancelBtnText: "Cancel"
    }
  },
  props: {
    propSpcmObj: {
      default: null,
      type: Object
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  components: {
    GPathrRoadmapExtraList
  },
  methods: {
    /**
     * getProileBuildingReco
     */
    async getProileBuildingReco () {
      try {
        let payload = {
          note_public_type: 1,
          note_type: 3, // 1 for note or 2 for comment or 3 for profile_building_activity recomendation
          spcm_id: this.propSpcmObj.spcm_id,
          filter: this.whereFilter
        }

        let profBuildRecoResp = await SpcmNotess.spcmNotesList(this, payload)
        if (profBuildRecoResp.resp_status) {
          this.profileBuildingRecoObj = profBuildRecoResp.resp_data.data
          if (profBuildRecoResp.pba_filter) {
            this.pbaFilterObjList = profBuildRecoResp.pba_filter
          }
        }
        else {
          this.profileBuildingRecoObj = {}
        }
      }
      catch (err) {
        console.error("Exception in getProileBuildingReco and err: ", err.message)
      }
    },
    /**
     * openPbaSelectionModal
     */
    openPbaSelectionModal () {
      // window.open(`/gpathRoadmapExtra_list?user_id=${this.propSpcmObj.user_id}&spcm_id=${this.propSpcmObj.spcm_id}`, "_blank")
      this.showMasterRecoList = true
    },
    /**
     * searchFromBackend
     */
    searchFromBackend () {
      if (this.whereFilter.search_param.length <= 3) {
        return
      }
      this.getProileBuildingReco()
    },
    /**
     * closeProfBuildRecoListModal
     */
    closeProfBuildRecoListModal () {
      this.showMasterRecoList = false
    },
    /**
     * emitAddProfBuildReco
     */
    emitAddProfBuildReco () {
      this.whereFilter = {
        search_param: "",
        pba_type: ""
      }

      this.getProileBuildingReco()
    },
    /**
     * removeProfBuildReco
     */
    async removeProfBuildReco (activity) {
      try {
        const spcmNotesDeleteResp = await SpcmNotess.spcmNotesDelete(this, activity.note_id, activity.note_type)
        if (spcmNotesDeleteResp.resp_status) {
          this.getProileBuildingReco()
          this.toastMsg = "Recommendation removed"
          this.toastVariant = "success"
          this.showToast = true
          return
        }
        else {
          ApiResponse.responseMessageDisplay(this, spcmNotesDeleteResp)
        }
      }
      catch (err) {
        console.error("Exception in removeProfBuildReco() and err: ", err.message)
      }
    },
    /**
     * gotoActivityURL
    */
    gotoActivityURL (url) {
      window.open(url, "_blank")
    }
  }
}
</script>
<style lang="">
</style>
