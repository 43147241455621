<template lang="">
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card class="p-3">
          <b-row>
            <h5 class="m-bold pl-2 w-100 pr-2 mb-2">Milestones</h5>
          </b-row>
          <div v-if="stuentCurrentMonthMilestoneObjectList">
            <div v-if="stuentCurrentMonthMilestoneObjectList" class="mb-2">
               <!-- University Counttry Filter -->
               <select v-model="milestoneNo" class="form-control pointer" @change="loadStudentMilestoneNumberWise()" title= "Milestone Number Filter">
                  <option v-for="(milestoneFilterObj, index) of studentCurrentMonthMilestoneNumberFilter" :key="(index+1)" :value="milestoneFilterObj.student_milestone_number">
                    {{milestoneFilterObj.student_milestone_number}}
                  </option>
              </select><!-- University Counttry Filter -->
            </div>

            <ul style="list-style: disc; padding-inline-start:20px">
              <li class="mb-3" v-for="(currentMnthMilestoneObj, index) in stuentCurrentMonthMilestoneObjectList" :key="index">
                <h6>{{currentMnthMilestoneObj.ms_title}}</h6>
                <p v-if="currentMnthMilestoneObj.ms_desc">{{currentMnthMilestoneObj.ms_desc}}</p>
              </li>
            </ul>
            <span v-if="isPreviousMilestoneBtnVisible" class="pull-right primary-color pointer" @click="loadAllMilestonesUptoCurrentMonth()">Previous Months</span>
          </div>
          <div v-else>
            <span v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'" class="primary-color font-size-12">No milestones found, add the country strategy, the class and the counselling category of the student</span>
            <span v-else class="primary-color">No milestones found, please reach out to your counsellor</span>
          </div>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { spcmMilestones } from "../../../FackApi/api/Spcmmilestones"
import moment from "moment"

export default {
  name: "SpcmStudentMilestone",
  mounted () {
    if (this.$route.params.spcmId) {
      // counsellor/admin is viewing a student's spcm dashboard
      this.spcm_id = this.$route.params.spcmId
    }

    this.getStudentMilestonesForCurrentMonth()
    this.setDistinctMilestoneNumberFilter()
  },
  data () {
    return {
      spcm_id: null,
      stuentCurrentMonthMilestoneObjectList: null,
      studentCurrentMonthMilestoneNumberFilter: null,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Student's Milestones",
      isPreviousMilestoneBtnVisible: true,
      milestoneNo: "All"
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  methods: {
    /**
     * getStudentMilestonesForCurrentMonth
     */
    async getStudentMilestonesForCurrentMonth (milestoneNo = null) {
      try {
        let spcmUserMilestoneListResp = await spcmMilestones.spcm_studentMilestoneList(this, { user_id: this.userData.user_id, spcm_id: this.spcm_id, ms_no: milestoneNo })
        if (spcmUserMilestoneListResp.resp_status) {
          this.stuentCurrentMonthMilestoneObjectList = spcmUserMilestoneListResp.resp_data.data
        }
        else {
          this.isPreviousMilestoneBtnVisible = false
        }
      }
      catch (err) {
        console.error("Exception in getStudentMilestonesForCurrentMonth and err: ", err.message)
      }
    },
    /**
     * getAllMilestonesForUser
     * Gets all the milestones upto a particular month
     */
    async getAllMilestonesForUser (milestoneNo = null) {
      try {
        let uptoMonth = moment().month() + 1
        let spcmUserMilestoneListResp = await spcmMilestones.spcm_studentMilestoneList(this, { user_id: this.userData.user_id, spcm_id: this.spcm_id, ms_no: milestoneNo, upto_month: uptoMonth })
        if (spcmUserMilestoneListResp.resp_status) {
          this.stuentCurrentMonthMilestoneObjectList = spcmUserMilestoneListResp.resp_data.data
          this.isPreviousMilestoneBtnVisible = false
        }
      }
      catch (err) {
        console.error("Exception in getAllMilestonesForUser and err: ", err.message)
      }
    },
    /**
     * setDistinctMilestoneNumberFilter
     */
    async setDistinctMilestoneNumberFilter (uptoMonth = null) {
      try {
        const spcmDistinctStudentCurrentMonthMilestoneNumber = await spcmMilestones.spcm_distinctMilestoneNumberList(this, { user_id: this.userData.user_id, spcm_id: this.spcm_id, upto_month: uptoMonth })
        if (!spcmDistinctStudentCurrentMonthMilestoneNumber.resp_status) {
          return
        }
        this.studentCurrentMonthMilestoneNumberFilter = spcmDistinctStudentCurrentMonthMilestoneNumber.resp_data
        this.studentCurrentMonthMilestoneNumberFilter.push({ student_milestone_number: "All", is_selected: true })
      }
      catch (err) {
        console.error("Exception in setDistinctMilestoneNumberFilter and err: ", err)
      }
    },
    /**
     * loadStudentMilestoneNumberWise
     */
    loadStudentMilestoneNumberWise () {
      try {
        if (this.milestoneNo != "All") {
          if (this.isPreviousMilestoneBtnVisible) {
            // User is viewing the milestones of the current mont
            this.getStudentMilestonesForCurrentMonth(this.milestoneNo)
          }
          else {
            // User is viewing all the previous milestones
            this.getAllMilestonesForUser(this.milestoneNo)
          }
        }
        else {
          if (this.isPreviousMilestoneBtnVisible) {
            this.getStudentMilestonesForCurrentMonth()
          }
          else {
            this.getAllMilestonesForUser()
          }
        }
      }
      catch (err) {
        console.error("Exception in loadStudentMilestoneNumberWise and err:", err.message)
      }
    },
    /**
     * loadAllMilestonesUptoCurrentMonth
     */
    async loadAllMilestonesUptoCurrentMonth () {
      await this.getAllMilestonesForUser()
      let uptoMonth = moment().month() + 1
      this.setDistinctMilestoneNumberFilter(uptoMonth)
    }
  }
}
</script>
<style lang="">
</style>
