/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validation_yjsdocs_name">{{cvDocNameLabel}}</label>
                  <input
                    v-model="vmdocFormData.yjsdocs_name"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validation_yjsdocs_code">{{cvDocCodeLabel}}</label>
                  <select class="form-control mb-2" v-model="vmdocFormData.yjsdocs_code">
                    <option v-for="(docTypeName, docCode) of DocTypeJson" :key="docCode" :value="docCode">
                      {{docTypeName}}
                    </option>
                  </select>
                </div>
                <div class="col-md-12 mb-3" v-if="orgObjList">
                  <label for="validation_yjsdocs_code">{{cvUniversityLabel}}</label>
                  <select class="form-control mb-2" v-model="universityId">
                    <option v-for="(orgObj, orgId) of orgObjList" :key="orgId" :value="orgId">
                      {{orgObj.org_name}}
                    </option>
                  </select>
                </div>
                <div class="col-md-12 mb-3" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">
                  <label for="validation_yjsdocs_tags">{{cvDocTagsLabel}}</label>
                  <input
                    v-model="vmdocFormData.yjsdocs_tags"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">
                  <label for="validation_yjsdocs_params">{{cvDocParamsLabel}}</label>
                  <input
                    v-model="vmdocFormData.yjsdocs_params"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">
                  <label for="validation_yjsdocs_collaborators">{{cvDocCollaboratorsLabel}}</label>
                  <input
                    v-model="vmdocFormData.yjsdocs_collaborators"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="yjsDocsAdd()"
                >
                  {{ cvSubmitBtn }}
                </button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast
      v-model="showToast"
      name="Toast"
      :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000"
      :variant="toastVariant"
      :title="toastTitle"
    >
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { YjsDocs } from "../../../FackApi/api/YjsDocs.js"
import ApiResponse from "../../../Utils/apiResponse.js"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"
import DocTypeJson from "../../../FackApi/json/YjsDocType.json"
import { Organisations } from "../../../FackApi/api/organisation.js"

export default {
  name: "docAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propGideUser: {
      type: Object
    },
    propYjsDocObj: {
      type: Object
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  data () {
    return {
      DocTypeJson: DocTypeJson,
      cvCardTitle: "Add",
      cvSubmitBtn: "Add",
      cvModuleNameLabel: "Modules Name",
      cvModuleObjectIdLabel: "Module Id",
      cvDocNameLabel: "Document Name",
      cvDocCodeLabel: "Document Type",
      cvUniversityLabel: "University",
      cvDocTagsLabel: "Tags",
      cvDocParamsLabel: "Params",
      cvDocCollaboratorsLabel: "Collaborators",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "Document Added",
      vmdocFormData: Object.assign({}, this.initFormData()),
      vmdocDesc: null,
      vmdocType: null,
      orgObjList: null,
      universityId: null,
      gidUserId: null
    }
  },
  async beforeMount () {
    // Auth Access for Current User Role
    if (this.userData.user_role == "USERROLE11114") {
      // We will display only the sessions of the user, It is necessary when the user has multiple sessions and he/she wants to view the session
      // DONT CARE CASE
    }
    else if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.gidUserId = this.propGideUser && this.propGideUser.user_id ? this.propGideUser.user_id : this.userData.user_id

    this.vmdocFormData.module_name = this.propYjsDocObj.module_name

    if (this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118") {
      this.vmdocFormData.yjsdocs_collaborators = `${this.userData.user_id},${this.gidUserId}`
    }
    else {
      this.vmdocFormData.gide_user_id = this.userData.user_id
      this.vmdocFormData.yjsdocs_collaborators = this.userData.user_id
    }
    this.vmdocFormData.module_object_id = this.propYjsDocObj.module_object_id
  },
  methods: {
    /**
     * initFormData
     */
    initFormData () {
      return {
        "module_name": "",
        "module_object_id": "",
        "yjsdocs_name": "",
        "yjsdocs_code": "",
        "yjsdocs_tags": "",
        "yjsdocs_params": "",
        "yjsdocs_collaborators": "",
        "yjsdocs_added_by": ""
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmdocFormData) {
          if (!this.vmdocFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
      }
    },
    /**
     * yjsDocsAdd
     */
    async yjsDocsAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true
        if (this.universityId) {
          this.vmdocFormData.yjsdocs_params = JSON.stringify({
            university_id: this.universityId
          })
        }

        this.vmdocFormData.gide_user_id = this.gidUserId
        this.vmdocFormData.yjsdocs_added_by = this.userData.user_id

        let docsAddResp = await YjsDocs.yjsDocsAdd(this, this.vmdocFormData)
        await ApiResponse.responseMessageDisplay(this, docsAddResp)

        if (docsAddResp && !docsAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitClosedocAddModal", docsAddResp.resp_data)
        }

        this.vmdocFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at yjsDocsAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * organisationList
     */
    async organisationList () {
      try {
        const filter = {
          type: ["UNIV"],
          get_all_univ: 1
        }
        let organisationListResp = await Organisations.organisationList(this, filter)
        if (organisationListResp.resp_status) {
          this.orgObjList = organisationListResp.resp_data.data
        }
      }
      catch (err) {
        console.error("Exception in organisationList and err: ", err.message)
      }
    }
  },
  watch: {
    "vmdocFormData.yjsdocs_code": function (newVal) {
      console.log("new value ", newVal)
      if (newVal == "ESSAY") {
        this.organisationList()
      }
    }
  }
}
</script>
