<template>
  <b-container fluid>
    <b-row class="resorcesScholar">
      <b-col md="12" class="allCardList">
        <iq-card>
          <template v-slot:headerTitle>
            <b-row class="row mt-2">
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-6">
                <h4 class="card-title">
                  {{cvHeader}} Resources
                </h4>
              </b-col>
              <b-col class="col-12 col-sm-6 col-md-6 col-lg-6">
                <div class="head_buttons" v-if="cvHeader == 'Scholarships'">
                  <b-button variant="primary" @click="gpath_career_univ_scholarshipAdd">{{cvbtnAddNew}}</b-button>
                </div>
              </b-col>
            </b-row>
          </template>
        </iq-card>

        <iq-card class="p-2">
          <!-- Backend Search -->
          <b-row>
            <b-col class="col-12 col-sm-6 col-md-8 col-lg-6 mb-1">
              <input class="form-control"
              v-model="whereFilter.search_param"
              type="search"
              @input="searchUserWithParams"
              :placeholder="cvSearchText"
            /><!-- Backend Search -->
            </b-col>
            <b-col class="col-12 col-sm-6 col-md-4 col-lg-6" v-if="cvHeader == 'Scholarships'">
              <select v-model="whereFilter.country" class="form-control" @change="setFilters">
                <option :value="null" disabled>Choose Country</option>
                <option v-for="(country, index) of CountryList" :key="(index+1)" :value="country.country">
                  {{country.country}}
                </option>
              </select>
            </b-col>
          </b-row>
        </iq-card>

        <iq-card class="p-2">
          <b-col class="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
            <div class="iq-card iq-card-block blog blog-detail p-2" v-for="(item, index) in itemList" :key="index">
              <h4 style="text-transform: capitalize;" class="pl-3 p-0 my-2">{{item.title}}</h4>
              <div class="blog-description ml-3" >
                <span class="vhtmlTag vueHtmlParent">
                  {{ item.description }}&nbsp;&nbsp;<i v-if="item.url" class="fa-solid fa-arrow-right primary-color pointer" @click="gotoURL(item)"></i>
                </span><br>
                <span v-if="item.attr">
                  <span v-for="(attrItem, cindex) in item.attr.split(',')" :key="cindex">
                    <b-badge variant="primary" class="mr-2" v-on:click="setFilterParams(attrItem)"><span>&nbsp;{{ attrItem }}</span></b-badge>
                  </span>
                </span>
                <span v-if="item.class">
                  <span v-for="(attrItem, cindex) in item.class.split(',')" :key="cindex">
                    <b-badge variant="primary" class="mr-2" v-on:click="setFilterParams(attrItem)"><span>Class&nbsp;{{ attrItem }}</span></b-badge>
                  </span>
                </span>
              </div>
            </div>
          </b-col>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelGPathCareerUnivScholarshipAdd"
      scrollable
      :title="cvAddModalHeader"
      size="xl"
    >
      <GPathCareerUnivScholarshipAdd :propOpenedInModal="true" @emitCloseGPathCareerUnivScholarshipAddModal="closeGPathCareerUnivScholarshipAddModal" />
      <template #modal-footer="">
        <b-button size="sm" class="pull-left" @click="gpath_career_univ_scholarshipAdd('FULLPAGE')">
          {{cvbtnModalExpand}}
        </b-button>
        <b-button size="sm" @click="closeGPathCareerUnivScholarshipAddModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import { socialvue } from "../../../config/pluginInit.js"
import { Resources } from "../../../FackApi/api/Resources.js"
import SideBarMenu from "../../../Utils/sidebarMenu.js"
import moment from "moment"
import GPathCareerUnivScholarshipAdd from "../GPath/GPathCareerUnivScholarshipAdd.vue"
export default {
  name: "Resources",
  props: {
    propName: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      itemList: [],
      cvSearchText: "Type to Search",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Resources",
      cvHeader: "",
      sideBarItemsList: SideBarMenu.getSideBarMenuObjList(),
      isMobileDevice: window.__IS__MOBILE_DEVICE__,
      name: "",
      cvAddModalHeader: "Add GPath Career Univ Scholarship",
      whereFilter: {
        search_param: "",
        country: "null"
      },
      CountryList: [],
      showModelGPathCareerUnivScholarshipAdd: false,
      cvbtnModalExpand: "Expand",
      cvbtnModalCancel: "Cancel",
      cvbtnAddNew: "Add New"
    }
  },
  components: {
    GPathCareerUnivScholarshipAdd
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  mounted () {
    socialvue.index()
    this.name = this.propName ? this.propName : this.$route.params.name
    this.cvHeader = this.name
    this.resourceGPathCareerUnivScholarshipFilterList()
    this.getResourceList()
  },
  methods: {
    async setFilterParams (attrItem) {
      this.whereFilter.search_param = attrItem
      this.getResourceList()
    },
    /**
     * searchUserWithParams
     */
    async searchUserWithParams () {
      if (this.whereFilter.search_param.length == 0) {
        await this.getResourceList()
      }
      else if (this.whereFilter.search_param.length <= 3) {
        return
      }
      this.currentBatchNo = 1
      await this.getResourceList()
    },
    /**
     * get Resource List
     */
    async getResourceList (loadViaLoadMore = false) {
      let whereFilter = {}

      if (this.whereFilter.country != "null") {
        whereFilter.country = this.whereFilter.country
      }

      try {
        const resourceDataResp = await Resources.resourceGPathRoadmapExtraList(this, whereFilter)
        if (resourceDataResp && !resourceDataResp.resp_status) {
          this.toastMsg = resourceDataResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
          return
        }
        else {
          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.itemList = [ ...resourceDataResp.resp_data ]
          }
          else {
            this.itemList = [...resourceDataResp.resp_data]
          }
        }
      }
      catch (err) {
        console.error("Exception in getResourceList and err: ", err)
      }
    },
    /*
    * country List
    */
    async resourceGPathCareerUnivScholarshipFilterList () {
      try {
        let countryListResp = await Resources.resourceGPathCareerUnivScholarshipFilterList(this)
        if (countryListResp.resp_status) {
          this.CountryList = countryListResp.resp_data
        }
      }
      catch (err) {
        console.log("Exception occurred at resourceGPathCareerUnivScholarshipFilterList() and Exception:", err.message)
      }
    },
    /**
     * goto URL
     */
    gotoURL (item) {
      window.open(item.url, "_blank")
    },
    /**
     * setFilters
    */
    async setFilters () {
      await this.getResourceList()
    },
    /**
     * gpath_career_univ_scholarshipAdd
     */
    gpath_career_univ_scholarshipAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/gpath_career_univ_scholarship_add")
        }
        else {
          this.showModelGPathCareerUnivScholarshipAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_career_univ_scholarshipAdd() and Exception:", err.message)
      }
    },
    /**
     * closeGPathCareerUnivScholarshipAddModal
     */
    closeGPathCareerUnivScholarshipAddModal (gpathCareerUnivScholarshipAddData) {
      try {
        if (gpathCareerUnivScholarshipAddData) {
          if (this.gpath_career_univ_scholarshipObjList && this.gpath_career_univ_scholarshipObjList.length >= 1) {
            let gpathCareerUnivScholarshipObjLength = this.gpath_career_univ_scholarshipObjList.length
            let lastId = this.gpath_career_univ_scholarshipObjList[gpathCareerUnivScholarshipObjLength - 1]["id"]
            gpathCareerUnivScholarshipAddData.id = lastId + 1
          }
          else {
            this.gpath_career_univ_scholarshipObjList = []
            gpathCareerUnivScholarshipAddData.id = 11111
          }

          gpathCareerUnivScholarshipAddData.created_on = moment()
          this.gpath_career_univ_scholarshipObjList.unshift(gpathCareerUnivScholarshipAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelGPathCareerUnivScholarshipAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closegpath_career_univ_scholarshipAddModal() and Exception:", err.message)
      }
    }
  }
}
</script>
<style>
.iq-card-header{
  display:none
}
.ul-list{
  padding: 0px !important;
  line-height: normal !important;
  padding-left: 15px !important;
  list-style: disc;
}
.badge span{
  word-break: break-all;
  white-space: break-spaces;
}
</style>
