/*eslint-disable */
<template>
  <div>
    <b-progress height="5px" :value="100" animated max="100" class="mb-3" style="margin-top: -10px;" v-if="loading">
    </b-progress>
    <b-container v-else>
      <b-row>
        <b-col md="12" v-if="userInterestObjList && Object.keys(userInterestObjList).length > 0">
          <b-card no-body>
            <b-card-text class="p-0">
              <div
                v-for="(interests, index) in userInterestObjList"
                :key="index"
                class="mb-3">
                <div>
                  <span style="text-transform: capitalize;">
                    {{ userInterestCatObjList[index]['interest_cat_name'] }}
                  </span>
                </div>
                <div>
                  <span
                    v-for="interest in interests"
                    :key="interest.interest_id">
                    <b-badge
                      style="cursor:pointer;"
                      class="border mr-2 mb-2 font-weight-normal"
                      :style="setInterestChipStyle(userInterestCatObjList[index])"
                      variant="none"
                      @click="selectInterest(interest)">
                      <i class="fa fa-check mr-1" aria-hidden="true" v-if="selectedInterestObjList && selectedInterestObjList[interest.interest_id]"></i>
                      <span>{{ getResizedInterestName(interest.interest_name) }}</span>
                    </b-badge>
                  </span>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col v-else>
          <span>
            No Interests selected.
          </span>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<style>
.active_interest {
  background-color: #51B5FD!important;
  border-color: #51B5FD!important;
}
.active_interest span {
  color: #FFFFFF!important;
}
</style>
<script>
import { Interests } from "../FackApi/api/interest"
import { User } from "../FackApi/api/user"
import { EventBus } from "../main.js"

export default {
  name: "Interest",
  props: {
    propRefreshUserInterests: {
      default: 0
    },
    propUserId: {
      default: null
    }
  },
  components: {
  },
  created () {
    EventBus.$on("openUserInstrest", event => {
      this.$emit("emitUserInterestsNotAdded", event)
      this.$store.dispatch("User/setAskedForUserInterest", true)
    })
  },
  data () {
    return {
      cvCardTitle: "Interests",
      userInterestObjList: null,
      userInterestCatObjList: null,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Interest List Response",
      selectedInterestObjList: null,
      loading: false
    }
  },
  computed: {
    askedForUserInterest () {
      return this.$store.getters["User/getAskedForUserInterests"]
    },
    selectedUserInterests () {
      return this.$store.getters["User/getSelectedUserInterests"]
    },
    userData () {
      return { ...this.$store.getters["User/userData"] }
    },
    userInterest () {
      return { ...this.$store.getters["UserInterest/selectedUserInterest"] }
    }
  },
  mounted () {
    this.userInterestList()
    this.interestWithCategoryList()
  },
  methods: {
    /**
     * interestList
     */
    async userInterestList (isNewInterestAdded = false) {
      this.loading = true
      try {
        let payload = {
          current_user_id: this.userData.user_id
        }
        if (this.propUserId) {
          payload.current_user_id = this.propUserId
        }

        // check if the userInterest and userInterestCategory is present in store or not
        if (Object.keys(this.userInterest).length > 0 && !isNewInterestAdded) {
          this.userInterestObjList = this.userInterest.interest_list
          this.userInterestCatObjList = this.userInterest.interest_cat_list
          this.loading = false
          return
        }

        let userInterestListResp = await User.userInterestList(this, payload.current_user_id)
        if (userInterestListResp.resp_status) {
          this.userInterestObjList = userInterestListResp.resp_data.interest_list
          this.userInterestCatObjList = userInterestListResp.resp_data.interest_cat_list
          // commiting data into vuex store
          const userInterest = {
            interest_list: userInterestListResp.resp_data.interest_list,
            interest_cat_list: userInterestListResp.resp_data.interest_cat_list
          }
          this.$store.commit("UserInterest/addUserInterest", userInterest)
        }
        /*
        // Hide User Interest Permanently as it is very Intrusive
        else {
          if (!this.askedForUserInterest && this.userData.user_mobile_verified && Number(this.userData.user_mobile_verified) === 1) {
            this.$emit("emitUserInterestsNotAdded", true)
            await this.$store.dispatch("User/setAskedForUserInterest", true)
          }
        }
        */
      }
      catch (err) {
        console.error("Exception occurred at interestList() and Exception:", err.message)
      }
      this.loading = false
    },
    /**
     * interestWithCategoryList
     */
    async interestWithCategoryList () {
      try {
        if (this.selectedUserInterests && this.selectedUserInterests.length > 0) {
          let interestObj = {
            // eslint-disable-next-line no-useless-escape
            interest_ids_in: "('" + this.selectedUserInterests.join("\', \'") + "')"
          }
          let interestListResp = await Interests.interestList(this, interestObj)
          if (interestListResp && !interestListResp.resp_status) {
            return false
          }
          else {
            let interestObjList = {}
            if (interestListResp.resp_data && interestListResp.resp_data.data) {
              for (let interest of interestListResp.resp_data.data) {
                interestObjList[interest.interest_id] = interest
              }
            }

            if (Object.values(interestObjList).length > 0) {
              this.selectedInterestObjList = interestObjList
            }
            else {
              this.selectedInterestObjList = null
            }
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at interestWithCategoryList() and Exception:", err.message)
      }
    },
    /**
     * selectInterest
     */
    selectInterest (interest) {
      if (!this.selectedInterestObjList) {
        this.selectedInterestObjList = {}
      }
      if (this.selectedInterestObjList[interest.interest_id]) {
        this.$delete(this.selectedInterestObjList, interest.interest_id)
      }
      else {
        this.$set(this.selectedInterestObjList, interest.interest_id, interest)
      }
      let selectedInterestIds = []
      for (let interestId in this.selectedInterestObjList) {
        selectedInterestIds.push(interestId)
      }
      this.$store.dispatch("User/setSelectedUserInterest", selectedInterestIds)
    },
    /**
     * setInterestChipStyle
     */
    setInterestChipStyle (interestCat) {
      return {
        "color": "#FFFFFF",
        "border-color": interestCat["interest_cat_color"] + "!important",
        "background-color": interestCat["interest_cat_color"] + "!important"
      }
    },
    /**
     * getResizedInterestName
     */
    getResizedInterestName (interestName) {
      if (interestName.length > 35) {
        return interestName.substr(0, 35) + " ... "
      }
      return interestName
    }
  },
  watch: {
    propRefreshUserInterests () {
      if (this.propRefreshUserInterests) {
        this.userInterestList(true)
      }
    }
  }
}
</script>
