<template lang="">
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card class="p-3">
          <b-row>
            <h5 class="m-bold pl-2 w-100 pr-2 mb-2">
              Recommended Scholarships
              <i class="fa fa-graduation-cap pt-5px pull-right primary-color pointer float-right ml-3" aria-hidden="true" title="Explore All Scholarships"  v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'" @click="goTostudentScholarship()"></i>
            </h5>
          </b-row>

          <div v-if="scholarshipRecoObjList">
            <!-- NB: We are keeping the recommended universities in admission table -->
            <ol style="padding-inline-start:20px">
              <li class="mb-3 pointer" v-for="(schObj, index) in scholarshipRecoObjList" :key="index" @click="gotoURL(schObj.url)">
                <h5>
                  {{schObj.title}}
                </h5>
                <span>By: {{schObj.univ_name}}</span>
                <p class="primary-color" style="font-size: 0.8rem">{{schObj.description}}</p>
              </li>
            </ol>
          </div>

          <div v-else>
            Scholarships are displayed for shorlisted universities only.&nbsp;
            <span v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">
              <span class="primary-color pointer" @click="gotoURL('/resource/Scholarships')">Click here</span> to explore all scholarship
            </span>
          </div>
        </iq-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModelRecommendedScholarshipList"
      scrollable
      :title="cvRecommendedScholarshipsHeader"
      size="xxl"
    >
      <ResourceScholarships :propOpenedInModal="true" propName = "Scholarships"  @emitcloseRecommendedScholarshipModal="closeRecommendedScholarshipModal" />
      <template #modal-footer="">
        <b-button size="sm" @click="closeStudentScholarshipModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { Resources } from "../../../FackApi/api/Resources"
// import ApiResponse from "../../../Utils/apiResponse"
import ResourceScholarships from "../Resources/ResourceScholarships.vue"
export default {
  name: "SpcmStudentScholarshipReco",
  data () {
    return {
      scholarshipRecoObjList: null,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Scholarship Recommendations",
      cvRecommendedScholarshipsHeader: "Scholarship Recommended",
      name: "Scholarships",
      cvbtnModalCancel: "close",
      showModelRecommendedScholarshipList: false
    }
  },
  props: {
    propSpcmObj: {
      default: null,
      type: Object
    }
  },
  components: {
    ResourceScholarships
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  created () {
  },
  mounted () {
    this.getScholarshipReco()
  },
  methods: {
    /**
     * getScholarshipReco
     */
    async getScholarshipReco () {
      try {
        const payload = {}
        if (this.$route.params.spcmId) {
          payload.gide_user_id = this.propSpcmObj.user_id
          payload.user_role = "USERROLE11114"
        }
        else {
          payload.gide_user_id = this.userData.user_id
          payload.user_role = this.userData.user_role
        }
        payload.get_sch_of_reco_univ = true

        const scholarshipRecoListResp = await Resources.resourceGPathRoadmapExtraList(this, payload)
        if (scholarshipRecoListResp.resp_status) {
          this.scholarshipRecoObjList = scholarshipRecoListResp.resp_data
        }
      }
      catch (err) {
        console.error("Exception in getScholarshipReco() and err: ", err.message)
      }
    },
    /**
     * gotoURL
     */
    gotoURL (url) {
      window.open(url, "_blank")
    },
    /**
     * closeRecommendedScholarshipModal
     */
    closeRecommendedScholarshipModal () {
      try {
        this.showModelRecommendedScholarshipList = false
      }
      catch (err) {
        console.error("Exception occurred at closeRecommendedScholarshipModal() and Exception:", err.message)
      }
    },
    /**
     * goToOrganisationCourses
    */
    goTostudentScholarship () {
      this.showModelRecommendedScholarshipList = true
    }
  }
}
</script>
<style>
ul li::marker {
  color: red;
}
ol li::marker {
  color: #000;
}
</style>
