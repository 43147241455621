/*eslint-disable */
<template>
  <b-container fluid>
    <div>
      <iq-card>
        <template v-slot:headerTitle>
          <b-row class="mt-2">
            <b-col class="col-12 col-sm-6 col-md-6 col-lg-6">
              <h4 class="card-title">
                <i class="fa fa-solid ri-user-star-fill" aria-hidden="true" style="font-size: 24px; margin-right: 3px; color: var(--iq-primary);"></i>
                Career Counseling Notes
              </h4>
            </b-col>
            <b-col class="col-12 col-sm-6 col-md-6 col-lg-6">
              <b-button variant="primary" style="float: right;" @click="goToCounsellingDashboard" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">Counseling Dashboard</b-button>
            </b-col>
            <b-col class="col-12 col-sm-8 col-md-6 col-lg-6">
              <span class="pb-1" style="line-break:anywhere">
                Student Name:&nbsp;<span class="pointer primary-color" @click="gotoUserProfile(studentData.user_id)">{{ studentData.user_name }}</span> <br>
                Lead Strategist:&nbsp;<span class="pointer primary-color" @click="gotoUserProfile(spcmObj.counsellor_id)">{{ spcmObj.counsellor_email }}</span>
              </span>
            </b-col>
            <b-col class="col-12 col-sm-4 col-md-6 col-lg-6">
              <span class="pull-right">
                <b-button class="bg-color mt-1 pr-0" v-if="gpath_session_data"><i class="fa fa-map-signs pointer" aria-hidden="true" style="font-size: 20px; color: var(--iq-primary);" @click="goToGpathsReport(gpath_session_data.report)" title="View GPaths Report"></i></b-button>
                <b-button class="bg-color mt-1 pr-0"><i class="fa fa-star pointer ml-2" aria-hidden="true" style="font-size: 20px; color: var(--iq-primary);" @click="goToReportAnalysis()" title="View Student Pre-Counseling Report"></i></b-button>
                <b-button class="bg-color mt-1 pr-0"><i class="fa ri-question-answer-fill pointer" aria-hidden="true" style="font-size: 20px; color: var(--iq-primary);" @click="goToPreCounsellingQuiz()" title="View Student Pre-Counseling Quiz"></i></b-button>
                <b-button class="bg-color mt-1 pr-0" @click="spcmStudentCounsellorTeam(spcmObj)"><i class="font-size-18 fa fa-users primary-color" title="View Team" aria-hidden="true"></i></b-button>
                <b-button class="bg-color mt-1 pr-0" @click="openSendmailToUserModal(spcmObj)" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'"><i class="font-size-18 fa fa-envelope primary-color" title="Send Email" aria-hidden="true"></i></b-button>
                <b-button v-if="VUE_APP_ENV != 'prod'" class="bg-color mt-1 pr-0" @click="openStudentDocsList(spcmObj)"><i class="font-size-18 fa-regular fa-file-word primary-color" title="Docs"></i></b-button>
              </span>
            </b-col>
          </b-row>
        </template>
      </iq-card>
    </div>

    <div id="reviewAdmission">
      <!-- Tabs visible in student view workflow only -->
      <div class="iq-card showFlexListDiv">
        <div class="user-tabing showFlexList">
          <tab-nav v-if="!cvLoading && studentView && (this.spcmObj.spcm_total_session < 0 || !this.spcmObj.hasOwnProperty('spcm_total_session'))" :pills="true" id="pills-tab" class="nav nav-pills d-flex align-items-center justify-content-center profile-feed-items p-0 m-0">
            <div  class="flex-fill p-0" @click="selectedTab = cvTabObj.personalDetail">
              <tab-nav-items class="flex-fill p-0" :active="selectedTab === cvTabObj.personalDetail" id="pills-personalDetail-tab" href="#pills-personalDetail-tab"
                ariaControls="pills-personalDetail-tab" role="tab" :ariaSelected="selectedTab === cvTabObj.personalDetail" :title="cvTabObj.personalDetail" />
            </div>

            <div  class="flex-fill p-0" :class="!spcmObj.spcm_id ? 'disableClick' : ''"  @click="selectedTab = cvTabObj.education">
              <tab-nav-items class="flex-fill p-0" :active="selectedTab === cvTabObj.education" id="pills-activity-tab" href="#profile-activity"
                ariaControls="pills-profile" role="tab" :ariaSelected="selectedTab === cvTabObj.education" :title="cvTabObj.education" />
            </div>

            <div  class="flex-fill p-0" :class="!spcmObj.spcm_id ? 'disableClick' : ''"  @click="selectedTab = cvTabObj.chooseInterests">
              <tab-nav-items class="flex-fill p-0" :active="selectedTab === cvTabObj.chooseInterests" id="pills-chooseInterests-tab" href="#profile-chooseInterests"
                ariaControls="pills-chooseInterests" role="tab" :ariaSelected="selectedTab === cvTabObj.chooseInterests" :title="cvTabObj.chooseInterests" />
            </div>

            <div  class="flex-fill p-0" :class="!spcmObj.spcm_id ? 'disableClick' : ''" v-if="!spcmObj.spcm_id || spcmObj.spcm_total_session < 1"  @click="selectedTab = cvTabObj.chooseSlot">
              <tab-nav-items class="flex-fill p-0" :active="selectedTab === cvTabObj.chooseSlot" id="pills-chooseSlot-tab" href="#profile-chooseSlot"
                ariaControls="pills-chooseSlot" role="tab" :ariaSelected="selectedTab === cvTabObj.chooseSlot" :title="cvTabObj.chooseSlot" />
            </div>

            <div class="flex-fill p-0" :class="!spcmObj.spcm_id ? 'disableClick' : ''" @click="selectedTab = cvTabObj.counselling">
              <tab-nav-items  class="flex-fill p-0"  :active="selectedTab === cvTabObj.counselling" id="pills-counselling-tab"  href="#pills-counselling"
                ariaControls="pills-counselling" role="tab" :ariaSelected="selectedTab === cvTabObj.counselling" :title="cvTabObj.counselling" />
            </div>
          </tab-nav>
        </div>
      </div><!-- Tabs visible in student view workflow only -->

      <div class="iq-card showFlexListDiv">
        <b-container fluid class="p-0">
          <b-row class="m-0 Documents">
            <!-- Personal Information -->
            <b-col md="12" class="pl-4 pr-4 pb-4 pt-2" v-show="(selectedTab === cvTabObj.personalDetail || selectedTab === cvTabObj.review)">
              <PersonalInfo v-if="studentData && Object.keys(studentData).length" :propUserId="studentData.user_id" :propUserData="studentData" :propModuleType="'ADM'"/>
            </b-col><!-- Personal Information -->

            <!-- Education -->
            <b-col md="12" class="pl-4 pr-4 pb-4 pt-2" :key="spcmObj.spcm_id+'_Education'" v-show="(selectedTab === cvTabObj.education || selectedTab === cvTabObj.review)">
              <div class="mt-3 table_scroll">
              <h4>Education
                <span class="educationAddIcon" v-if="studentView && (!spcmObj.spcm_id || editMode)" @click="showAddEducation()"><i class="fa-solid fa-circle-plus"></i></span>
              </h4>
              <hr class="w-100 theme_color"/>
              <div class="mt-3" v-if="user_profile.education && user_profile.education.length">
                  <Education :propUserId="studentData.user_id" :propUserView="userData.user_id == spcmObj.user_id" :propEducationList="user_profile.education" :propExcObjList="extraCurricularListObj"  @emitUserProfileEducation="updatedEducationDetails"></Education>
              </div>
              </div>
            </b-col><!-- Education -->

            <!-- Choose Slot -->
            <b-col md="12" class="pl-4 pr-4 pb-4 pt-2"  v-show="(selectedTab === cvTabObj.chooseSlot || selectedTab === cvTabObj.review)">
              <div class="mt-3 table_scroll">
                <h4>Choose Slot
                </h4>
                <hr class="w-100 theme_color"/>
                <!-- show the choose slot to user only -->
                <div v-if="spcmObj && spcmObj.spcm_id && userData.user_role == 'USERROLE11114'">
                  <SpcmSessions :propSpcmId="spcmObj.spcm_id" :propSpcmObj="spcmObj" :propStudentData="studentData" @emitSessionUpdated="spcmSessionUpdate"></SpcmSessions>
                </div>
              </div>
            </b-col><!-- Choose Slot -->

            <!-- Choose Interests -->
            <b-col md="12" class="review_form_spacing" v-show="(selectedTab === cvTabObj.chooseInterests || selectedTab === cvTabObj.review)">
              <div class="mt-3 table_scroll">
                <h4>Choose Interests
                </h4>
                <hr class="w-100 theme_color"/>
                <Interest :propModalView="false" @emitCloseInterestModal="goToNextTab"></Interest>
              </div><!-- Choose Interests -->
            </b-col><!-- Choose Interests -->

            <!-- Main Implementation of Counseling Notes -->
            <b-col md="12" class="pl-4 pr-4 pb-4 pt-2 review_form_spacingSPCM mb-0 p-0" :key="spcmObj.spcm_id+ '_Session'" v-show="(selectedTab === cvTabObj.counselling)">
              <b-row>
                <!-- Left Most Sessions Tab  -->
                <b-col md="4" lg="2" class="right_data" v-if="leftmenuIcon || !IS_MOBILE_DEVICE">
                  <div class="right_data_center">
                  <template v-if="spcmObj.spcm_id">
                    <div class="col-md-12 mb-3 p-0">
                    <i v-if="IS_MOBILE_DEVICE" @click="leftmenuIcon = !leftmenuIcon" class="fa fa-times close_left_data" aria-hidden="true"></i>
                      <SpcmSessions :propSpcmId="spcmObj.spcm_id" :propSpcmObj="spcmObj" :propStudentData="studentData" :propViewList="true"></SpcmSessions>
                    </div>
                  </template>
                  </div>
                </b-col><!-- Left Most Sessions Tab  -->

                <!-- Center Notes Tab  -->
                <b-col md="12" lg="6">
                  <div class="main-circle" @click="leftmenuIcon = !leftmenuIcon">
                    <i class="ri-menu-line menu_icon_left "></i>
                  </div>
                  <section v-if="spcmObj && spcmObj.spcm_id">
                    <SpcmNoteList :propSpcmObj="spcmObj"></SpcmNoteList>
                  </section>
                  <div @click="rightmenuIcon = !rightmenuIcon" class="main-circle">
                    <i class="ri-menu-line menu_icon_right"></i>
                  </div>
                </b-col><!-- Center Notes Tab  -->

                <!-- Right Most Action Menus  -->
                <b-col sm="8" md="6" lg="4" class="right_data " v-if="rightmenuIcon || !IS_MOBILE_DEVICE">
                  <div class="right_data_center">
                    <i v-if="IS_MOBILE_DEVICE" @click="rightmenuIcon = !rightmenuIcon" class="fa fa-times close_right_data" aria-hidden="true"></i>
                    <div>
                      <h6 class="mt-2">
                        <ul class="nav nav-pills mb-3 nav-fill nav-tabs" id="pills-tab-1" role="tablist">
                          <li class="nav-item" @click="counsellingSelectedTab = cvTabObj.milestoneReco">
                            <a class="nav-link" active id="pills-milestoneReco-tab-fill" data-toggle="pill" href="#pills-milestoneReco-fill" role="tab" aria-controls="pills-milestoneReco" aria-selected="true">
                              <i class="fa fa-calendar-check-o" aria-hidden="true" :title="cvTabObj.milestoneReco"></i>
                            </a>
                          </li>
                          <li class="nav-item" @click="counsellingSelectedTab = cvTabObj.universityReco">
                            <a class="nav-link" id="pills-universityReco-tab-fill" data-toggle="pill" href="#pills-universityReco-fill" role="tab" aria-controls="pills-universityReco" aria-selected="true">
                              <i class="fa fa-university" aria-hidden="true" :title="cvTabObj.universityReco"></i>
                            </a>
                          </li>
                          <li class="nav-item" @click="counsellingSelectedTab = cvTabObj.scholarshipReco">
                            <a class="nav-link" id="pills-scholarshipReco-tab-fill" data-toggle="pill" href="#pills-scholarshipReco-fill" role="tab" aria-controls="pills-scholarshipReco" aria-selected="true">
                              <i class="fa fa-graduation-cap" aria-hidden="true" :title="cvTabObj.scholarshipReco"></i>
                            </a>
                          </li>
                          <li class="nav-item" @click="counsellingSelectedTab = cvTabObj.profileBuildingReco">
                            <a class="nav-link" id="pills-profileBuildingReco-tab-fill" data-toggle="pill" href="#pills-profileBuildingReco-fill" role="tab" aria-controls="pills-profileBuildingReco" aria-selected="true">
                              <i class="fa fa-th-list" aria-hidden="true" :title="cvTabObj.profileBuildingReco"></i>
                            </a>
                          </li>
                          <li class="nav-item" @click="counsellingSelectedTab = cvTabObj.programTest">
                            <a class="nav-link" id="pills-programTest-tab-fill" data-toggle="pill" href="#pills-programTest-fill" role="tab" aria-controls="pills-programTest" aria-selected="true">
                              <i class="fa fa-file-text" aria-hidden="true" :title="cvTabObj.programTest"></i>
                            </a>
                          </li>
                          <li class="nav-item" @click="counsellingSelectedTab = cvTabObj.personalDetail">
                            <a class="nav-link" id="pills-personalDetail-tab-fill" data-toggle="pill" href="#pills-personalDetail-fill" role="tab" aria-controls="pills-personalDetail" aria-selected="true">
                              <i class="fa fa-user" aria-hidden="true" :title="cvTabObj.personalDetail"></i>
                            </a>
                          </li>
                          <li class="nav-item" @click="counsellingSelectedTab = cvTabObj.education">
                            <a class="nav-link" id="pills-education-tab-fill" data-toggle="pill" href="#pills-education-fill" role="tab" aria-controls="pills-education" aria-selected="false">
                              <i class="fa fa-school" aria-hidden="true" :title="cvTabObj.education"></i>
                            </a>
                          </li>
                          <li class="nav-item" @click="counsellingSelectedTab = cvTabObj.leaderBoard">
                            <a class="nav-link" id="pills-leaderBoard-tab-fill" data-toggle="pill" href="#pills-leaderBoard-fill" role="tab" aria-controls="pills-leaderBoard" aria-selected="false">
                              <i class="fa fa-bar-chart" aria-hidden="true" :title="cvTabObj.leaderBoard"></i>
                            </a>
                          </li>
                        </ul>
                      </h6>
                    </div>

                    <!-- Spcm Milestones -->
                    <div v-show="counsellingSelectedTab == cvTabObj.milestoneReco">
                      <div class="mt-3">
                        <SpcmStudentMilestone />
                      </div>
                    </div><!-- Spcm Milestones -->

                    <!-- University Recommendations -->
                    <div v-show="counsellingSelectedTab == cvTabObj.universityReco">
                      <div class="mt-3">
                        <SpcmStudentUniversityReco @refreshScholarshipReco="refreshScholarshipReco" :propSpcmObj="spcmObj"/>
                      </div>
                    </div><!-- University Recommendations -->

                    <!-- Profile Building Recommendations -->
                    <div v-show="counsellingSelectedTab == cvTabObj.profileBuildingReco">
                      <div class="mt-3">
                        <SpcmStudentProfileBuildingReco :propSpcmObj="spcmObj"/>
                      </div>
                    </div><!-- Profile Building Recommendations -->

                    <!-- Programs & Test -->
                    <div v-show="counsellingSelectedTab == cvTabObj.programTest">
                      <div class="mt-3">
                          <div class="iq-card">
                            <div class="iq-card-body">
                              <div class="">
                                <h5 class="m-bold pl-2 w-100 pr-2 mb-2">
                                  Programs &amp; Test
                                  <span v-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111' || userData.user_id  == studentData.user_id" class="educationAddIcon" @click="showProgramModal"><i class="fa-solid fa-circle-plus"></i></span>
                                </h5>
                                <div v-if="userProgramTest && Object.keys(userProgramTest).length">
                                  <div class="educationCard pt-2" v-for="(item, index) in userProgramTest" :key="index+'program'" >
                                  <ProgramTest :propGideUser="studentData" :propProgramDetail="item" :propOpenedInModal="false"></ProgramTest>
                                  </div>
                                </div>
                                <div v-else class="mb-4 ml-2">
                                  Student has not updated this section
                                </div>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div><!-- Programs & Test -->

                    <!-- Scholarship Recommendations -->
                    <div v-show="counsellingSelectedTab == cvTabObj.scholarshipReco">
                      <div class="mt-3">
                        <SpcmStudentScholarshipReco :key="refreshScholarshipRecoKey" :propSpcmObj="spcmObj"/>
                      </div>
                    </div><!-- Scholarship Recommendations -->

                    <div v-show="counsellingSelectedTab == cvTabObj.personalDetail">
                      <div class="mt-3">
                        <PersonalInfo :propUserId="spcmObj.user_id" :propUserData="studentData" :propModuleType="'SPCM'"/>
                      </div>
                    </div>
                    <div class="p-3 iq-card" v-show="counsellingSelectedTab == cvTabObj.chooseInterests">
                      <UserInterest v-if="spcmObj.user_id" :propUserId="spcmObj.user_id"/>
                    </div>
                    <div class="" v-show="counsellingSelectedTab == cvTabObj.leaderBoard">
                      <div class="p-3  iq-card" v-if="spcmObj.user_id">
                        <AppResultLeaderBoard :propUserId="spcmObj.user_id" />
                      </div>
                    </div>
                    <div class="p-3 iq-card" v-show="counsellingSelectedTab == cvTabObj.education">
                      <div v-if="user_profile.education && user_profile.education.length">
                        <Education :propUserId="spcmObj.user_id" :propUserView="userData.user_id == spcmObj.user_id" :propEducationList="user_profile.education" :propExcObjList="extraCurricularListObj" @emitUserProfileEducation="updatedEducationDetails"></Education>
                      </div>
                    </div>
                  </div>
                </b-col><!-- Right Most Action Menus  -->
              </b-row>
            </b-col><!-- Main Implementation of Counseling Notes -->
          </b-row>
        </b-container>

        <template v-if="addEducationShow">
          <Education :propEducationForm="educationForm" :propEducationAddShow='addEducationShow' @emitCloseEducationModal="closeEducationModal" @emitUserProfileEducation="updatedEducationDetails">
          </Education>
        </template>

        <!-- SendMail -->
        <b-modal
          v-model="showModelSendMail"
          scrollable
          :title="cvSendMailModalHeader"
        >
          <SendMail :propOpenedInModal="true" :propSendMailObj="sendMailObj" :propModuleName="GideProducts.COUNSELLING" @emitCloseGPathSendMailModal="emitCloseGPathSendMailModal" />
          <template #modal-footer="">
            <b-button size="sm" @click="emitCloseGPathSendMailModal()">
              {{cvbtnModalCancel}}
            </b-button>
          </template>
        </b-modal><!-- SendMail -->

        <!--Spcm Student Counsellor Team -->
        <b-modal
          v-model="showModelSpcmStudentCounsellorTeam"
          scrollable
          :title="cvSpcmStudentCounsellorTeamModalHeader"
          size="xxl"
          hide-footer>
          <SpcmStudentCounsellorTeam :propStudentCounselorTeam="true" :propOpenedInModal="true" :propStudentCounsellorObj="StudentCounsellorTeamObj" @emitCloseStudentCounsellorTeamModal="closeStudentCounsellorTeamModal" />
        </b-modal><!-- Spcm Student Counsellor Team -->

        <!-- Yjs Docs-->
        <b-modal
          v-model="showModelYjs_Doc_List"
          scrollable
          :title="cvYjsDocListModalHeader"
          size="xxl"
          hide-footer
          >
          <YjsDocsList :propOpenedInModal="true" :propYjsDocObj="yjsDocsObj" :propGideUser="studentData" @emitCloseYjsDocsModal="closeStudentDocsList"/>
        </b-modal><!-- Yjs Docs-->

        <!-- Next Button -->
        <div class="footer app_form__admin_three_button mt-2 mb-2 row w-100 ">
          <div class="col-12 mt-1 pt-1 submit_sudent_form pr-0" v-if="selectedTab != cvTabObj.chooseSlot && selectedTab != cvTabObj.counselling && selectedTab != cvTabObj.chooseInterests">
            <b-button size="sm" variant="primary" class="pull-right primary mb-3" @click="goToNextTab()">
              {{selectedTab === cvTabObj.review ? 'Pay and Submit' : 'Save and Next'}}
            </b-button>
          </div>
        </div>

        <ProgramTest :propGideUser="studentData"  v-if="showProgramTestModal" :propProgramDetail="programEditObj" :propOpenedInModal="showProgramTestModal" @emitCloseProgramModal="closeProgramModal"></ProgramTest>

        <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
          <div v-html="toastMsg"></div>
        </b-toast>
      </div>
    </div>
  </b-container>
</template>

<script>
import { socialvue } from "../../../config/pluginInit.js"
import { User } from "../../../FackApi/api/user.js"
import Utility from "../../../Utils/utility.js"
import Education from "../../../components/UserProfile/Education.vue"
import PersonalInfo from "../../../components/UserProfile/PersonalInfo.vue"
import SpcmNoteList from "../../../components/SpcmNoteList.vue"
import SpcmSessions from "../../../components/SpcmSessions.vue"
import { Spcms } from "../../../FackApi/api/Spcm.js"
import SPCMStateJson from "../../../FackApi/json/SPCMState.json"
import ApiResponse from "../../../Utils/apiResponse.js"
import SPCMStateNumberJson from "../../../FackApi/json/SPCMStateNumber.json"
import { AppResults } from "../../../FackApi/api/appResults.js"
import AppResultLeaderBoard from "../../../components/AppResultLeaderBoard.vue"
import Interest from "../../../components/Interest.vue"
import UserInterest from "../../../components/UserInterest.vue"
import { QuizBanks } from "../../../FackApi/api/quizBank.js"
import { GPathSessions } from "../../../FackApi/api/GPathSession.js"
import userPermission from "../../../Utils/user_permission.js"
import SpcmStudentMilestone from "./SpcmStudentMilestone.vue"
import SpcmStudentUniversityReco from "./SpcmStudentUniversityReco.vue"
import SpcmStudentProfileBuildingReco from "./SpcmStudentProfileBuildingReco.vue"
import SpcmStudentScholarshipReco from "./SpcmStudentScholarshipReco.vue"
import SpcmStudentCounsellorTeam from "./SpcmStudentCounsellorTeam.vue"
import SendMail from "../../../components/SendMail.vue"
import SendMailModules from "../../../FackApi/json/SendMailModules.json"
import GideProducts from "../../../FackApi/json/GideProducts.json"
import ProgramTest from "../../../components/UserProfile/ProgramTest.vue"
import YjsDocsList from "../YjsDocs/YjsDocsList.vue"

export default {
  name: "SpcmView",
  components: {
    Education,
    PersonalInfo,
    SpcmNoteList,
    SpcmSessions,
    AppResultLeaderBoard,
    Interest,
    UserInterest,
    SpcmStudentUniversityReco,
    SpcmStudentMilestone,
    SpcmStudentProfileBuildingReco,
    SpcmStudentScholarshipReco,
    SpcmStudentCounsellorTeam,
    SendMail,
    ProgramTest,
    YjsDocsList
  },
  data () {
    return {
      apiName: "spcm_add",
      SendMailModules: SendMailModules,
      GideProducts: GideProducts,
      vmQuestionerForm: {},
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Counselling Response",
      spcmObj: {},
      editMode: false,
      user_profile: {},
      extraCurricularListObj: {},
      userResponded: true,
      cvTabObj: {
        personalDetail: "Personal Details",
        education: "Education Details",
        chooseSlot: "Choose your slot",
        review: "Review",
        counselling: "Counseling",
        leaderBoard: "Activities",
        chooseInterests: "Choose Interests",
        milestoneReco: "Milestone Recommendations",
        universityReco: "University List Recommendations",
        scholarshipReco: "Scholarship Recommendations",
        profileBuildingReco: "Profile Building Recommendations",
        programTest: "Programs, Exams & Tests"
      },
      gpath_session_data: null,
      gpath_session_counnt: null,
      selectedTab: "Personal Details",
      counsellingSelectedTab: "Personal Details",
      cvSpcmStudentCounsellorTeamModalHeader: "Student Counselor Team",
      showModelSpcmStudentCounsellorTeam: false,
      StudentCounsellorTeamObj: null,
      vmBannerImage: Utility.getBgImageForLetter(this, "S"),
      studentView: true,
      counsellorView: false,
      counsellorId: null,
      addEducationShow: false,
      studentData: {},
      userProgramTest: {},
      sessionList: [],
      showSlotModal: false,
      spcmId: null,
      SPCMStateJson: SPCMStateJson,
      availableSlotList: [],
      userAppAllResultAverage: [],
      cvLoading: true,
      updateCounsellingTab: 0,
      questionList: [],
      rightmenuIcon: false,
      leftmenuIcon: false,
      IS_MOBILE_DEVICE: true,
      PRE_COUNSELING_APP_ID: "PRE_COUNSELING_APP_ID",
      refreshScholarshipRecoKey: 0,
      showModelSendMail: false,
      cvSendMailModalHeader: "Send Email",
      sendMailObj: null,
      cvbtnModalCancel: "Cancel",
      VUE_APP_ENV: process.env.VUE_APP_ENV,
      showModelYjs_Doc_List: false,
      cvYjsDocListModalHeader: "Student Docs List",
      yjsDocsObj: null,
      showProgramTestModal: false
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  async beforeMount () {
    this.spcmId = this.$route.params.spcmId
    await this.viewSpcmDetail()

    if (this.userData.user_role == "USERROLE11118" && this.spcmObj.counsellor_id != this.userData.user_id) {
      // Auth Access for Current User Role
      if (!userPermission(this, this.userData, this.apiName)) {
        this.$router.back()
      }
    }
    else if (this.userData.user_role == "USERROLE11114" && this.spcmObj.user_id != this.userData.user_id) {
      // the user is not a counsellor and he is trying to  open another student's dashboard
      this.$router.push("/spcm")
    }
  },
  async mounted () {
    socialvue.index()

    if (window.innerWidth > 991) {
      this.IS_MOBILE_DEVICE = false
    }

    this.spcmId = this.$route.params.spcmId
    this.spcmObj = await this.viewSpcmDetail()
    if (this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118") {
      this.studentView = false
      this.counsellorView = true
      this.counsellorId = this.userData.user_id
      this.selectedTab = this.cvTabObj.counselling

      if (this.userData.user_role == "USERROLE11118" && !this.spcmObj.counselor_team.includes(this.counsellorId) && this.spcmObj.counsellor_id != this.userData.user_id) {
        this.$router.push("/spcm_counseling_dashboard")
      }
    }

    this.studentGPathsReportView()
    this.viewUserProfile()
  },
  methods: {
    /**
     * getUserAppAllResultAverage
     */
    async getUserAppAllResultAverage () {
      const apiResp = await AppResults.appResultAllAverage(this, { current_user_id: this.spcmObj.user_id })
      if (apiResp.resp_status) {
        this.userAppAllResultAverage = apiResp.resp_data.data
      }
    },
    /**
     * spcmSessionUpdate
     */
    spcmSessionUpdate (event) {
      if (event.session_id) {
        this.spcmObj.spcm_total_session += 1
      }
      this.selectedTab = this.cvTabObj.counselling
      this.showSlotModal = false
      // this.spcmAddEdit()
    },
    /**
     * goTo GpathsReport
     */
    async goToGpathsReport (url) {
      if (this.gpath_session_counnt > 1) {
        Utility.openUrlInNewTab(`/gpath_session_list?user_id=${this.studentData.user_id}`)
      }
      else {
        Utility.openUrlInNewTab(url, "_blank")
      }
    },
    /**
     * goTo ReportAnalysis
     * <!-- https://web.gide.ai/analyse_app_result/APP3d1f54056a6e4704a10e691083ea961d/3ae28a5fcb108bc1ddd784521bbb4f0d?attempt=NA -->
     */
    async goToReportAnalysis () {
      Utility.openUrlInNewTab(`/analyse_app_result/${this.PRE_COUNSELING_APP_ID}/${this.studentData.user_id}?attempt=NA`, "_blank")
    },
    /**
     * goTo goToPreCounsellingQuiz
     * https://web.gide.ai/quiz_detail/APP3d1f54056a6e4704a10e691083ea961d
     */
    async goToPreCounsellingQuiz () {
      Utility.openUrlInNewTab(`/quiz_detail/${this.PRE_COUNSELING_APP_ID}`, "_blank")
    },
    /**
     * goToCounsellingDashboard
     */
    goToCounsellingDashboard () {
      this.$router.push("/spcm_counseling_dashboard")
    },
    /**
     * gotoUserProfile
     */
    gotoUserProfile (userId) {
      Utility.openUrlInNewTab(`/user/${userId}`, "_blank")
    },
    /**
     * showAddSession
     */
    showAddSession (e) {
      if (e) {
        this.showSlotModal = true
      }
      else {
        this.showSlotModal = true
      }
    },
    /**
     * showAddEducation
     */
    showAddEducation () {
      this.addEducationShow = true
      this.educationSubmited = false
      this.educationForm = {
        subjects: [],
        videoLinks: [],
        attachments: []
      }
    },
    /**
     * closeEducationModal
     */
    closeEducationModal () {
      this.addEducationShow = false
    },
    /*
     * updated Education
     */
    async updatedEducationDetails (payload) {
      this.viewUserProfile()
      this.addEducationShow = false
    },
    /**
     * viewSpcmDetail
     */
    async viewSpcmDetail () {
      const spcmResp = await Spcms.spcmView(this, this.spcmId)
      if (!spcmResp.resp_status) {
        if (this.spcmId) {
          // show only if couseller view bcz incase of student it will come from user id. if spcm not found in db then it will show add form
          ApiResponse.responseMessageDisplay(this, spcmResp)
        }
        else {
          this.spcmObj.user_id = this.userData.user_id
          this.studentData = this.userData
          this.viewUserProfile()
        }
        this.cvLoading = false
        return
      }

      this.spcmObj = spcmResp.resp_data.data
      this.spcmId = this.spcmObj.spcm_id
      this.spcmObj.user_id = this.spcmObj.user_id

      if (this.userData.user_id === this.spcmObj.user_id) {
        this.studentData = this.userData
      }
      else {
        this.studentData = spcmResp.resp_data.user_detail
      }

      this.viewUserProfile()

      if (!this.spcmObj.spcm_total_session) {
        this.spcmObj.spcm_total_session = 0
      }

      // If there exists an entry in the spcm table then it implies that the user is onboarded, and we do not need to collect his personal details and interests
      if (this.spcmObj.spcm_total_session >= 0) {
        this.selectedTab = this.cvTabObj.counselling
      }

      if (this.counsellorView) {
        this.getUserAppAllResultAverage()
      }
      this.getSpcmQuizResponse()
      this.cvLoading = false
      return this.spcmObj
    },
    /**
     * studentGPathsReportView
     */
    async studentGPathsReportView () {
      // See if the user has a gpaths report or not
      const gpathSessionViewResp = await GPathSessions.gpath_sessionView(this, null, { student_id: this.studentData.user_id })
      if (gpathSessionViewResp.resp_status) {
        this.gpath_session_data = gpathSessionViewResp.resp_data.data
        this.gpath_session_counnt = gpathSessionViewResp.resp_data_count
      }
    },
    /**
     * getSpcmQuizResponse
    */
    async getSpcmQuizResponse () {
      let payload = {
        app_category: "Pre-Counselling",
        curr_user_id: this.spcmObj.user_id
      }
      const resp = await QuizBanks.quizBankUserResponse(this, payload)
      if (resp.resp_status) {
        this.questionList = resp.resp_data.data
        this.userResponded = false
      }
    },
    /**
     * viewUserProfile
     */
    async viewUserProfile () {
      const userProfile = await User.userProfileView(this, this.spcmObj.user_id)
      if (userProfile.resp_status) {
        this.extraCurricularListObj = userProfile.resp_data.data.extraCurricularListObj

        if (userProfile.resp_data.data.user_profile) {
          if (typeof userProfile.user_profile === "string") {
            this.user_profile = JSON.parse(userProfile.resp_data.data.user_profile)
          }
          else {
            this.user_profile = userProfile.resp_data.data.user_profile
          }
        }

        if (userProfile.resp_data.data.user_program_test) {
          this.userProgramTest = JSON.parse(userProfile.resp_data.data.user_program_test)
        }
      }
    },
    /**
     * goToNextTab
     */
    goToNextTab () {
      switch (this.selectedTab) {
        case this.cvTabObj.personalDetail:
          if (this.spcmObj && this.spcmObj.spcm_id) {
            this.selectedTab = this.cvTabObj.education
          }
          else {
            this.spcmAddEdit()
            this.selectedTab = this.cvTabObj.education
          }
          break
        case this.cvTabObj.education:
          this.selectedTab = this.cvTabObj.chooseInterests
          break
        case this.cvTabObj.chooseInterests:
          this.selectedTab = this.cvTabObj.chooseSlot
          break
        case this.cvTabObj.chooseSlot:
          // seesion add will call
          break
        case this.cvTabObj.review:
          this.selectedTab = this.cvTabObj.counselling
          break
        case this.cvTabObj.counselling:
          this.$router.push("/spcm_dashboard")
          break
        default:
          break
      }
    },
    /**
     * spcmAddEdit
     */
    async spcmAddEdit () {
      try {
        let payload = {
          spcm_state: null,
          spcm_id: this.spcmId,
          counsellor_id: this.counsellorId
        }
        let apiResp = null

        if (this.spcmId) {
          if (this.studentView && this.selectedTab == this.cvTabObj.review) {
            switch (this.spcmObj.spcm_state.toString()) {
              case "1":
                payload.spcm_state = SPCMStateNumberJson[Number(this.spcmObj.spcm_state) + 1] // SPCM_DONE
                break
              default:
                this.$router.back()
                break
            }
          }
          else {
            // Counseller View
            // @tobe remove shubham if no use
            console.log("Counsellor View")
          }
          apiResp = await Spcms.spcmEdit(this, payload)
        }
        else {
          payload.spcm_state = SPCMStateNumberJson[1]
          apiResp = await Spcms.spcmAdd(this, payload)
          if (apiResp.resp_status) {
            this.spcmId = apiResp.resp_data.spcm_id
            this.viewSpcmDetail()
          }
          else {
            ApiResponse.responseMessageDisplay(this, apiResp)
          }
        }
      }
      catch (error) {
        console.error("Exception in spcmAddEdit()", error)
      }
    },
    /**
     * refreshScholarshipReco
     */
    refreshScholarshipReco () {
      this.refreshScholarshipRecoKey++
    },
    /**
     * spcmStudentCounsellorTeam
     */
    spcmStudentCounsellorTeam (item) {
      try {
        item.user_name = this.studentData.user_name
        this.StudentCounsellorTeamObj = item
        this.showModelSpcmStudentCounsellorTeam = true
      }
      catch (err) {
        console.error("Exception occurred at spcmStudentCounsellorTeam() and Exception:", err.message)
      }
      // this.$router.push(`/spcmStudentCounsellor_team/${spcmCounsellorId}`)
    },
    /**
     * closeStudentCounsellorTeamModal
     */
    closeStudentCounsellorTeamModal (updatedData) {
      this.showModelSpcmStudentCounsellorTeam = false
    },
    /**
     * openSendmailToUserModal
     */
    openSendmailToUserModal () {
      this.sendMailObj = {
        module_name: this.GideProducts.COUNSELLING,
        module_obj_id: this.spcmObj.spcm_id,
        user_id: this.studentData.user_id,
        counsellor_id: this.spcmObj.counsellor_id,
        spcm_id: this.spcmObj.spcm_id,
        user_name: this.studentData.user_name,
        user_email: this.studentData.user_email,
        cc_email: this.studentData.user_email,
        subject: {}
      }

      for (let key in this.SendMailModules) {
        if (key.includes("SPCM")) {
          this.sendMailObj.subject[key] = this.SendMailModules[key]
        }
      }

      this.showModelSendMail = true
    },
    /**
     * emitCloseGPathSendMailModal
     */
    async emitCloseGPathSendMailModal () {
      this.showModelSendMail = false
    },
    /**
      * openStudentDocsList
      */
    openStudentDocsList (item) {
      try {
        item.module_name = "SPCM"
        item.module_object_id = this.spcmId
        this.yjsDocsObj = item
        this.showModelYjs_Doc_List = true
      }
      catch (err) {
        console.error("Exception occurred at showModelYjs_Doc_List() and Exception:", err.message)
      }
    },
    /**
     * closeStudentDocsList
     */
    closeStudentDocsList () {
      this.showModelYjs_Doc_List = false
    },
    /**
     * showAddProgramAndTest
      */
    showProgramModal () {
      this.showProgramTestModal = true
      this.programEditObj = {}
    },
    /**
     * closeProgramModal
     */
    closeProgramModal (event) {
      if (event) {
        this.userProgramTest = JSON.parse(event.resp_data.user_program_test)
      }
      this.showProgramTestModal = false
    }
  }
}
</script>

<style lang="scss">
  .educationAddIcon {
    float: right;
    color: var(--iq-primary);;
  }
  .dropdown-item{
    color: #212529 !important;
  }
  .dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:hover{
    background: transparent;
    color: var(--iq-primary) !important;
  }
  .logo-icon{
    height: 50px !important;
    width: 50px !important;
    overflow: hidden;
    object-fit: cover;
    pointer-events: none;
    border-radius: 50%;
  }
  .w-full{
    width: 100%;
  }
  .btn-opacity{
    border: none ;
    outline : none;
    background:#fff;
    width: 100%;
  }
  .btn-opacity:hover{
    color: var(--iq-primary)
  }
  .line-wrap{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }
  .grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr)
  }
  @media screen and (max-width: 768px){
    .grid{
       grid-template-columns: 0.9fr 1.2fr 0.9fr
    }
  }
  #photo-grid img {
    background: #FFFFFF!important;
    object-fit: fill;
  }
  .leftArrowIcon {
    position: absolute;
    left: 0px;
    z-index: 99;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 32px;
    background-color: transparent !important;
    border: none;
    i {
      font-size: 22px;
    }
  }
  .rightArrowIcon {
    position: absolute;
    right: 0px;
    z-index: 99;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 32px;
    background-color: transparent !important;
    border: none;
    i {
      font-size: 22px;
    }
  }
  .review_form_spacingSPCM {
    padding: 11px !important;
    z-index: 100;
  }
  .questionContainer {
    white-space: normal;
    height: 100%;
    width: 100%;
  .optionContainer {
      margin-top: 0px;
      flex-grow: 1;
      .option {
        border-radius: 5px;
        margin-bottom: 15px;
        padding: 5px 10px 5px 50px;
        cursor: pointer;
        border: transparent 1px solid #f1f1f1;
        position: relative;
        border: 1px solid #f1f1f1;
        overflow: hidden;
        font-size: 18px;
        height: 40px;
        &.is-selected {
          border-color: var(--iq-primary);
          background-color: white;
        }
        &.is-correct {
          border-color: rgb(92, 180, 92);
        }
      }
    }
    .questionFooter {
      width: 100%;
      align-self: flex-end;
      .pagination {
        //padding: 10px 15px;
        margin: 15px 25px;
      }
      .progressContainer {
        // margin: 15px 25px;
      }
    }
  }
  .optionNo {
  position: absolute;
  top: 0;
  bottom: 0px;
  width: 40px;
  background-color: #f1f1f1;
  left: 0px;
  }
  .optionNo span {
    position: absolute;
    right: 0px;
    left: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    width: 17px;
    height: 23px;
    font-weight: 500;
    line-height: initial;
    font-size: 18px;
  }

  #pills-tab-1{
    li a{
    border-radius: 0px !important;
    }
  }
  #pills-tab-1.nav-tabs .nav-item.show .nav-link,.nav-tabs .nav-link.active{
    border-bottom:1px solid !important;
    }
  @media screen and (max-width: 991px){
    .right_data{
      position: absolute;
      top: 0;
      bottom: 0;
      background: #00000038;
      min-height: 100%;
      margin: auto;
      height:max-content;
      max-width: 100%;
      z-index: 99999;
    }
    .right_data_center{
      position: absolute;
      top: 10px;
      height: max-content;
      margin: auto;
      background: #fff;
      left: 10px;
      right: 10px;
      min-height: 305px;
    }
    .main-circle{
      float: right;
      font-size: 20px;
      display: block !important;
    }
    .menu_icon_right{
      position: absolute;
      right: 8px;
      top: 12px;
    }
    .menu_icon_left{
      position: absolute;
      left: 8px;
      top: 12px;
    }
  }
  @media screen and (min-width: 991px){
  .right_data{
    display: block;
    }
  }
  .main-circle{
    display: none;
  }
  .close_right_data{
    float: right;
    font-size: 19px !important;
    position: absolute;
    top: 3px;
    right: 6px;
  }
  .close_left_data{
    float: right;
    font-size: 19px !important;
    position: absolute;
    top: 5px;
    right: 6px;
  }
.Documents{
  .document_hr_color{
    border-color: var(--iq-primary) !important;
  }
  .document_card{
    padding-left: 0px !important;
    box-shadow: none !important;
  }
}
.iq-header-title{
  width: 100%;
}
.bg-color{
  background-color: transparent !important;
  border: 0px;
}
</style>
