<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <form action="#" v-if="vmdocFormData && Object.keys(vmdocFormData).length > 0">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validation_yjsdocs_name">{{cvDocNameLabel}}</label>
                  <input
                    v-model="vmdocFormData.yjsdocs_name"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <label for="validation_yjsdocs_code">{{cvDocCodeLabel}}</label>
                  <select class="form-control mb-2" v-model="vmdocFormData.yjsdocs_code">
                    <option v-for="(docTypeName, docCode) of DocTypeJson" :key="docCode" :value="docCode">
                      {{docTypeName}}
                    </option>
                  </select>
                </div>
                <div class="col-md-12 mb-3" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">
                  <label for="validation_yjsdocs_tags">{{cvDocTagsLabel}}</label>
                  <input
                    v-model="vmdocFormData.yjsdocs_tags"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">
                  <label for="validation_yjsdocs_params">{{cvDocParamsLabel}}</label>
                  <input
                    v-model="vmdocFormData.yjsdocs_params"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col-md-12 mb-3" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">
                  <label for="validation_yjsdocs_collaborators">{{cvDocCollaboratorsLabel}}</label>
                  <input
                    v-model="vmdocFormData.yjsdocs_collaborators"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="yjsDocsEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { YjsDocs } from "../../../FackApi/api/YjsDocs.js"
import ApiResponse from "../../../Utils/apiResponse.js"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"
import DocTypeJson from "../../../FackApi/json/YjsDocType.json"

export default {
  name: "docEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propYjsDocObj: {
      type: Object,
      default: function () {
        return {
          "module_name": "",
          "module_object_id": "",
          "yjsdocs_name": "",
          "yjsdocs_code": "",
          "yjsdocs_tags": "",
          "yjsdocs_params": "",
          "yjsdocs_collaborators": ""
        }
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  data () {
    return {
      DocTypeJson: DocTypeJson,
      cvCardTitle: "Edit Documents",
      cvSubmitBtn: "Edit",
      cvModuleNameLabel: "Module Name",
      cvModuleObjectIdLabel: "Module Id",
      cvDocNameLabel: "Document Name",
      cvDocCodeLabel: "Document Type",
      cvDocTagsLabel: "Tags",
      cvDocParamsLabel: "Params",
      cvDocCollaboratorsLabel: "Collaborators",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Document Updated",
      vmdocFormData: {}
    }
  },
  async beforeMount () {
    // Auth Access for Current User Role
    if (this.userData.user_role == "USERROLE11114") {
      // We will display only the sessions of the user, It is necessary when the user has multiple sessions and he/she wants to view the session
      // DONT CARE CASE
    }
    else if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.yjsDocsView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmdocFormData) {
          if (!this.vmdocFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * yjsDocsView
     */
    async yjsDocsView () {
      try {
        if (this.propOpenedInModal) {
          this.vmdocFormData = this.propYjsDocObj
        }
        else {
          let docsId = this.$route.params.yjsdocsId
          let docsViewResp = await YjsDocs.yjsDocsView(this, docsId)

          if (docsViewResp && docsViewResp.resp_status) {
            this.vmdocFormData = docsViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at yjsDocsView() and Exception:", err.message)
      }
    },
    /**
     * yjsDocsEdit
     */
    async yjsDocsEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let docsAddResp = await YjsDocs.yjsDocsEdit(this, this.vmdocFormData)
        await ApiResponse.responseMessageDisplay(this, docsAddResp)

        if (docsAddResp && !docsAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitClosedocEditModal", this.vmdocFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at yjsDocsEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
