<template>
  <b-container fluid class="p-0">
    <b-row>
      <!-- Appoint Gide COunsellors as SPCM Counsellors -->
      <b-modal v-model="showappointSpcmCounselorModal" size="xl" title="Add Counselor" hide-footer>
        <SpcmCounsellorAdd :propOpenedInModal="true" @emitCloseSpcmCounsellorAddModal="emitCloseSpcmCounsellorAddModal" />
      </b-modal><!-- Appoint Gide COunsellors as SPCM Counsellors -->

      <b-col lg="12">
        <iq-card style="min-height: 40vh;">
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <div class="row">
                    <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                      <label for="">{{ cvSelecteCounsellorTeam }} </label>
                    </div>
                    <div class="col-12 col-sm-6 col-sm-6 col-lg-6">
                      <button v-if="userData.user_role == 'USERROLE11111'" type="button" class="btn btn-primary pull-right" @click="openAppointCounsellorModel()" title="View Team">Appoint Counselor</button>
                    </div>
                  </div>
                    <b-form-checkbox-group
                      id="checkbox-group-1"
                      v-model="selectedCounsellor"
                      :options="spcmCounsellorsList"
                      name="flavour-1"
                      stacked
                    />
                  </div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary mr-2" @click="spcmTeamUpdate()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { Spcms } from "../../../FackApi/api/Spcm"
import userPermission from "../../../Utils/user_permission.js"
import { SpcmCounsellors } from "../../../FackApi/api/SpcmCounsellor.js"
import SpcmCounsellorAdd from "./SpcmCounsellorAdd.vue"

export default {
  name: "SpcmManageTeam",
  components: {
    // Multiselect,
    SpcmCounsellorAdd
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propCounsellorObj: {
      type: Object,
      default: function () {
        return {
          "counsellor_id": "",
          "spcm_state": null
        }
      }
    }
  },
  data () {
    return {
      apiName: "spcm_edit",
      cvCardTitle: "Edit Student Counseling Team",
      cvSubmitBtn: "Save",
      cvLoadingStatus: false,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Student Counseling Team",
      cvSelecteCounsellorTeam: "Select Counseling Team",
      selectedCounsellor: [],
      spcmCounsellorsList: [],
      showappointSpcmCounselorModal: false
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    await this.displayCurrentTeam()
    await this.spcmCounsellorList()
  },
  methods: {
    /**
     * display Current team
     */
    async displayCurrentTeam () {
      try {
        if (this.propOpenedInModal) {
          if (this.propCounsellorObj.counselor_team && this.propCounsellorObj.counselor_team.length > 0) {
            this.selectedCounsellor = this.propCounsellorObj.counselor_team.split(",")
          }
          else {
            this.selectedCounsellor.push(this.propCounsellorObj.counsellor_id)
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at displayCurrentTeam() and Exception:", err.message)
      }
    },
    /**
     * Update Team
    */
    async spcmTeamUpdate () {
      try {
        if (this.propCounsellorObj) {
          this.propCounsellorObj.counselor_team = this.selectedCounsellor.join(",")
        }

        if (!this.propCounsellorObj.counselor_team) {
          this.toastMsg = "Team can't be empty"
          this.toastVariant = "danger"
          this.showToast = true
          return
        }

        let spcmAddResp = await Spcms.spcmEdit(this, this.propCounsellorObj)
        if (spcmAddResp && !spcmAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseSpcmManageTeamModal", this.propCounsellorObj)
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmTeamUpdate() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * spcmCounsellorList
    */
    async spcmCounsellorList () {
      try {
        let spcmCounsellorListResp = await SpcmCounsellors.spcmCounsellorList(this)
        if (spcmCounsellorListResp.resp_status) {
          spcmCounsellorListResp = spcmCounsellorListResp.resp_data.data
          for (let key of spcmCounsellorListResp) {
            this.spcmCounsellorsList.push({ text: `${key.user_name}`, value: key.counsellor_user_id })
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmCounsellorList() and Exception:", err)
      }
    },
    /**
     * openAppointCounsellorModel
     */
    openAppointCounsellorModel () {
      this.showappointSpcmCounselorModal = true
    },
    /**
     * emitCloseSpcmCounsellorAddModal
     */
    emitCloseSpcmCounsellorAddModal (newSpcmCounsellor) {
      this.spcmCounsellorsList.push({ text: `${newSpcmCounsellor.user_name}`, value: newSpcmCounsellor.counsellor_user_id })
    }
  }
}
</script>
